import DataS from "./data.s";

export default class RequestS extends DataS {
  getRequest() {
    return this.get('/requests')
  }

  getRequestConcerned(id, years = null) {
    return this.patch('/requests/user/' + id, {years})
  }
  
  getRequestBloc(id) {
    return this.get('/requests/user/' + id)
  }
  
  rejectRequest(id) {
    return this.post('/requests/reject', {id})
  }
  
  setPrinted(id) {
    return this.post('/requests/print', {id})
  }
  
  getReport(config) {
    return this.post('/requests/report', config)
  }
}
