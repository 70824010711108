  import DataS from "./data.s";

export default class DilplomaS extends DataS {
  getByEmployee(MATRICULE) {
    return this.get('/documents/' + MATRICULE)
  }

  remove(data) {
    return this.post('/documents/delete', data)
  }

  createDiploma({DESIGNATION, SPECIALITY, LEVEL, LANGUAGE, OBTENEDDATE, OBTENEDSTAB, MATRICULE, FILE}) {
    return this.post('/documents', {
      DESIGNATION,
      SPECIALITY,
      LEVEL,
      LANGUAGE,
      OBTENEDDATE,
      OBTENEDSTAB,
      EMPLOYEE: MATRICULE,
      FILE
    })
  }
}
