import DataS from "./data.s";

export default class UserS extends DataS {

  login(data) {
    return this.post('/auth', data)
  }

  updateAdmin(data) {
    return this.patch('/users', data)
  }

  getUser(id) {
    return this.get('/users/' + id)
  }

  getUsers() {
    return this.get('/users')
  }

  createAdmin(data) {
    return this.post('/users/create', data)
  }

  signInWithFederate(federate) {
    return this.post('/auth/federate', federate)
  }
}
