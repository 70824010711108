import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import classnames from "classnames"
import logo from "../../../../assets/img/logo/logo.png"

class SidebarHeader extends Component {
  render() {
    let {
      menuShadow
    } = this.props
    return (
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <NavLink to="/" className="flex items-center py-3 gap-3">
              <img src={logo} width={28} height={28} alt='logo' />
              <h2 className="brand-text mb-0 text-xl font-semibold">GALIO</h2>
            </NavLink>
          </li>
        </ul>
        <div
          className={classnames("shadow-bottom", {
            "d-none": menuShadow === false
          })}
        />
      </div>
    )
  }
}

export default SidebarHeader
