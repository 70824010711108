import DataS from "./data.s";

export default class RequestGroupS extends DataS {
  getRequestGroup() {
    return this.get('/request-groups')
  }

  updateRequestGroup(id, REQUEST_GROUP_ID, REQ_GROUP_NAME, REQ_GROUP_MANAGER) {
    return this.patch('/request-groups', {id, REQUEST_GROUP_ID, REQ_GROUP_NAME, REQ_GROUP_MANAGER})
  }
}
