import DataS from "./data.s";

export default class SubjectsS extends DataS {
  getSubjectClass(id) {
    return this.post('/subjects', {id})
  }
  
  listOfSubjects() {
    return this.get('/subjects')
  }
  
  
  getByLevel({page, session, CYCLE, LEVEL, REGIME, CLASS, SEARCH}) {
    return this.patch('/subjects/by-level/' + page, {session, CYCLE, LEVEL, REGIME, CLASS, SEARCH})
  }
}
