import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {FormattedMessage} from "react-intl";

export default function MsgDialog({handleNo, handleOk, handleYes, msgDesc, msgTitle, msgOk, open}) {
  return (
    <Dialog
      open={open || false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage id={msgTitle}/>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage id={msgDesc}/>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!msgOk && <>
          <Button onClick={handleYes}><FormattedMessage id='Yes'/></Button>
          <Button onClick={handleNo} autoFocus><FormattedMessage id='No'/></Button>
        </>}
        {msgOk && <>
          <Button onClick={handleOk}>Okay</Button>
        </>}

      </DialogActions>
    </Dialog>
  );
}
