import React, { useContext, useEffect } from "react";
import CookieH from "../../../helpers/cookie.h";
import { Menu } from "@mui/base/Menu";
import { MenuItem } from "@mui/base/MenuItem";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { CssTransition, Dropdown, PopupContext } from "@mui/base";
import { styled } from "@mui/system";
import userDefault from "./../../../assets/img/pages/user-default.jpg";
import ReactCountryFlag from "react-country-flag";
import { IntlContext } from "../../../utility/Internationalization";

const UserDropdown = () => {
  return (
    <Dropdown>
      <BaseMenuButton>
        <div className="flex gap-3">
          <div className="text-right">
            <div className="user-name text-bold-600">
              {CookieH.getUser()?.USERFULLNAME}
            </div>
            <div className="user-status font-semibold">
              {CookieH.getUser()?.USERPROFILE}
            </div>
          </div>
          <span data-tour="user h-100">
              <img
                src={userDefault}
                className="rounded-full h-100"
                height="40"
                width="40"
                alt="default user"
              />
            </span>
        </div>
      </BaseMenuButton>
      <Menu slots={{ listbox: AnimatedListbox }}>
        <MenuItem
          className="cursor-pointer select-none px-2 py-2 z-10"
          onClick={() => {
            if (CookieH.getUser()) CookieH.clearUser();
          }}
        >Log out</MenuItem>
      </Menu>
    </Dropdown>
  );
};

const Listbox = styled("ul")(({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0;
  background: #fff";
  border: 1px solid #aeaeae;
  color: #646464;
  box-shadow: 0px 4px 30px #aeaeae;
  z-index: 1;

  .closed & {
    opacity: 0;
    transform: scale(0.95, 0.8);
    transition: opacity 200ms ease-in, transform 200ms ease-in;
  }
  
  .open & {
    opacity: 1;
    transform: scale(1, 1);
    transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
  }

  .placement-top & {
    transform-origin: bottom;
  }

  .placement-bottom & {
    transform-origin: top;
  }
  `);
const AnimatedListbox = React.forwardRef(function AnimatedListbox(props, ref) {
  const { ownerState, ...other } = props;
  const popupContext = React.useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
    );
  }

  const verticalPlacement = popupContext.placement.split("-")[0];

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <Listbox {...other} ref={ref} />
    </CssTransition>
  );
});

const NavbarUser = () => {
  useEffect(() => {
    if (!Boolean(CookieH.getUser())) {
      window.location = ("/login");
    }
  }, []);

  let langArr = {
    "en": "English",
    "fr": "French"
  };

  const context = useContext(IntlContext);

  return (
    <ul className="flex gap-6 justify-end w-full z-50">
      <Dropdown>
        <BaseMenuButton className="nav-link">
          <div>
            <ReactCountryFlag
              className="country-flag rounded-full border border-black" svg
              style={{ width: 32, height: 32 }}
              countryCode={context.state.locale === "en" ? "us" : context.state.locale}
            />
          </div>
        </BaseMenuButton>
        <Menu slots={{ listbox: AnimatedListbox }}>
          <MenuItem onClick={() => context.switchLanguage("en")}>
            <div className='flex gap-3 py-1 px-2 items-center cursor-pointer hover:bg-gray-50'>
              <ReactCountryFlag className="country-flag" countryCode="us" svg />
              <span className="ml-1">English</span>
            </div>
          </MenuItem>
          <MenuItem onClick={() => context.switchLanguage("fr")}>
            <div className='flex gap-3 py-1 px-2 items-center cursor-pointer hover:bg-gray-50'>
              <ReactCountryFlag className="country-flag" countryCode="fr" svg />
              <span className="ml-1">French</span>
            </div>
          </MenuItem>
        </Menu>
      </Dropdown>
      <UserDropdown />
    </ul>
  );
};

export default NavbarUser;
