import React from "react"
import {Button} from "@mui/material"
import CookieC from "../../constants/cookie.c";
import {GlobalContext} from "../../utility/Global";
import {FormattedMessage} from "react-intl";

const Footer = props => {
  return (<GlobalContext.Consumer>
      {context => (<footer className='col-span-12'>
          <div className="mb-0 items-center flex px-3">
            <div className="mt-25 col px-0 flex-1">
              COPYRIGHT © IUC {new Date().getFullYear()}&nbsp;&nbsp; <FormattedMessage id='All rights reserved'/>
            </div>
            <div className='flex col-auto items-center items-center ml-2'>
              Version :<Button
              style={{paddingLeft: 4, paddingRight: 4}}
              onClick={() => context.setState({openFeature: true})}>{CookieC.VERSION}</Button></div>
          </div>
        </footer>)}
    </GlobalContext.Consumer>)
}

export default Footer
