import React from 'react';
import sessionExpired from '../../assets/img/png/sessionExpired.png';
import {Slide} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Button} from "reactstrap";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SessionExpired({open, handleClose}) {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div id="alert-dialog-slide-title">
          <h3 className='text-center mt-3 text-lg capitalize'>Session time expired</h3>
        </div>
        <DialogContent className='text-center flex items-center justify-center flex-col'>
          <img src={sessionExpired} alt="#"/>
          <p id="alert-dialog-slide-description" style={{fontSize: '1.5em'}}>
            Your session time has been expired and you will be now redirected to the login page to sign again
          </p>
        </DialogContent>
        <DialogActions className={'text-center'}>
          <Button onClick={handleClose} className='bg-blue-500 px-8 py-2 rounded text-white'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
