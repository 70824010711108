import DataS from "./data.s";

export default class EvaluationFormS extends DataS {
  saveEvaluationForm(data) {
    return this.post('/evaluation-forms',data)
  }
  updateEvaluationForm(data) {
    return this.patch('/evaluation-forms',data)
  }
  listEvaluationForm(data) {
    return this.get('/evaluation-forms',data)
  }
  evaluationFormDelete(id) {
    return this.get('/evaluation-forms/delete/'+id)
  }
  evaluationFormDuplicate(id) {
    return this.get('/evaluation-forms/duplicate/'+id)
  }
}
