import DataS from "./data.s";

export default class AcademicYearS extends DataS {
  
  gets() {
    return this.get('/years')
  }
  imports() {
    return this.get('/years/import')
  }
}
