import DataS from "./data.s";

export default class SessionApplicationS extends DataS {

  create({START_DATE, END_DATE, DESIGNATION, MANUAL_CLOSURE, ACADEMIC_YEAR}) {
    return this.post('/sessions-applications', {START_DATE, END_DATE, DESIGNATION, MANUAL_CLOSURE, ACADEMIC_YEAR})
  }

  gets() {
    return this.get('/sessions-applications')
  }

  setActived(data) {
    return this.post('/sessions-applications/activation', data)
  }
}
