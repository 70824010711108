import React, {lazy, Suspense} from "react"
import {IntlProviderWrapper} from "./utility/Internationalization"
import {Layout} from "./utility/Layout"
import * as serviceWorker from "./serviceWorker"
import Spinner from "./components/spinner/Fallback-spinner"
import "./index.scss"
import {MsalProvider} from "@azure/msal-react";
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./authConfig";
import {createRoot} from 'react-dom/client';

const LazyApp = lazy(() => import("./App"))
const msalInstance = new PublicClientApplication(msalConfig);
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <Suspense fallback={<Spinner/>}>
      <Layout>
        <IntlProviderWrapper>
          <MsalProvider instance={msalInstance}>
            <LazyApp/>
          </MsalProvider>
        </IntlProviderWrapper>
      </Layout>
    </Suspense>
  </React.StrictMode>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
