import React, { Component } from "react";
import classnames from "classnames";
import SidebarHeader from "./SidebarHeader";
import Hammer from "react-hammerjs-18";
import SideMenuContent from "./sidemenu/SideMenuContent";
import PerfectScrollbar from "react-perfect-scrollbar";
import { GlobalContext } from "../../../../utility/Global";

class Sidebar extends Component {
  state = {
    width: window.innerWidth,
    activeIndex: null,
    hoveredMenuItem: null,
    activeItem: this.props.activePath,
    menuShadow: false,
    ScrollbarTag: PerfectScrollbar
  };
  mounted = false;

  static getDerivedStateFromProps(props, state) {
    if (props.activePath !== state.activeItem) {
      return {
        activeItem: props.activePath
      };
    }
    return null;
  }

  updateWidth = () => {
    if (this.mounted) {
      this.setState(() => ({
        width: window.innerWidth
      }));
      this.checkDevice();
    }
  };

  componentDidMount() {
    this.mounted = true;
    if (this.mounted) {
      if (window !== "undefined") window.addEventListener("resize", this.updateWidth, false);
      this.checkDevice();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  checkDevice = () => {
    var prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
    var mq = query => window.matchMedia(query).matches;
    if ("ontouchstart" in window || window.DocumentTouch) {
      this.setState({ ScrollbarTag: "div" });
    } else this.setState({ ScrollbarTag: PerfectScrollbar });
    return mq(["(", prefixes.join("touch-enabled),("), "heartz", ")"].join(""));
  };

  changeActiveIndex = id => {
    if (id !== this.state.activeIndex) this.setState({ activeIndex: id });
    else this.setState({ activeIndex: null });
  };

  handleSidebarMouseEnter = id => {
    if (id !== this.state.hoveredMenuItem) this.setState({ hoveredMenuItem: id });
    else this.setState({ hoveredMenuItem: null });
  };

  handleActiveItem = url => this.setState({ activeItem: url });

  render() {
    let {
      toggle,
      color,
      sidebarVisibility,
      activeTheme,
      activePath,
      sidebarState,
      currentLang,
      permission,
      currentUser,
      collapsedMenuPaths
    } = this.props;

    let {
      menuShadow,
      activeIndex,
      hoveredMenuItem,
      activeItem,
      ScrollbarTag
    } = this.state;
    return (
      <GlobalContext.Consumer>
        {gContext => {

          const toggleSidebarMenu = () => {
            gContext.setState({ collapsedContent: !gContext.state.collapsedContent, collapsed: true });
          };

          const sidebarHover = (collapsed) => {
            gContext.setState({ collapsed });
          };

          return (
            <div className='w-[250px] shadow-lg px-3'>
              <>
                <Hammer
                  onSwipe={sidebarVisibility}
                  direction="DIRECTION_RIGHT">
                  <div className="menu-swipe-area d-xl-none block vh-100"></div>
                </Hammer>
                <div
                  className={classnames(
                    `main-menu menu-fixed menu-light menu-accordion menu-shadow theme-${activeTheme}`,
                  )}
                  onMouseEnter={() => sidebarHover(false)}
                  onMouseLeave={() => sidebarHover(true)}>
                  <SidebarHeader
                    toggleSidebarMenu={toggleSidebarMenu}
                    toggle={toggle}
                    sidebarBgColor={color}
                    sidebarVisibility={sidebarVisibility}
                    activeTheme={activeTheme}
                    collapsed={gContext.state.collapsedContent}
                    menuShadow={menuShadow}
                    activePath={activePath}
                    sidebarState={sidebarState}
                  />
                  <ScrollbarTag
                    className={classnames("main-menu-content", {
                      "overflow-hidden": ScrollbarTag !== "div",
                    })}
                    {...(ScrollbarTag !== "div" && { options: { wheelPropagation: false } })}>
                    <Hammer
                      onSwipe={sidebarVisibility}
                      direction="DIRECTION_LEFT">
                      <ul className="navigation navigation-main">
                        <SideMenuContent
                          setActiveIndex={this.changeActiveIndex}
                          activeIndex={activeIndex}
                          hoverIndex={hoveredMenuItem}
                          handleSidebarMouseEnter={this.handleSidebarMouseEnter}
                          activeItemState={activeItem}
                          handleActiveItem={this.handleActiveItem}
                          activePath={activePath}
                          lang={currentLang}
                          permission={permission}
                          currentUser={currentUser}
                          collapsedMenuPaths={collapsedMenuPaths}
                          toggleMenu={sidebarVisibility}
                          deviceWidth={this.props.deviceWidth}
                        />
                      </ul>
                    </Hammer>
                  </ScrollbarTag>
                </div>
              </>
            </div>
          );
        }}
      </GlobalContext.Consumer>
    );
  }
}

export default Sidebar;
