import DataS from "./data.s";

export default class RequestBatchS extends DataS {
  createBatch(values) {
    return this.post('/request-batches', values)
  }

  getBatches(DEST_ID) {
    return this.get('/request-batches/' + DEST_ID)
  }

  confirmBatchReception(reference) {
    return this.patch('/request-batches', {reference})
  }

  markTransmitted(batchesId) {
    return this.post('/request-batches/transmit', {batchesId})
  }

  cancelBatch(batchId) {
    return this.get('/request-batches/cancel/' + batchId)
  }
}
